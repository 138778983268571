<template>
  <div>
    <v-sheet dark color="accent" v-if="!$vuetify.breakpoint.mobile">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="6" lg="6" class="text-end">
          <div class="font-weight-bold mb-4 title1" style="line-height: 1">
            Entenda mais sobre a <br />
            Lei Geral de Proteção <br />
            de Dados de Forma<br />
            Simplificada e como <br />
            Implantá-la!
          </div>
          <div class="font-weight-light subtitle1">
            Através deste
            <b class="font-weight-bold">E-book GRATUITO</b><br />
            você estará por dentro da <b class="font-weight-bold">LGPD</b><br />
            e ainda saberá o
            <b class="font-weight-bold">
              Passo a Passo <br />
              para Implementá-la no seu <br />
              Empreendimento.
            </b>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" class="text-start">
          <div>
            <v-img
              src="@/assets/landing-page/ebook/new_ebook.png"
              max-width="50%"
            />
            <v-btn
              align="start"
              rounded
              large
              dark
              class="mt-5 my-10 orange"
              @click="active = true"
            >
              Clique AQUI e garanta seu E-book GRÁTIS!
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-sheet>

    <v-container class="accent white--text" v-else>
      <v-row align="center">
        <v-col cols="12" sm="6" md="6" lg="6" align="center">
          <div class="d-flex flex-column justify-space-between align-center">
            <v-img
              src="@/assets/landing-page/ebook/new_ebook.png"
              max-width="100%"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" align="center">
          <div class="white--text text--center"></div>
          <div class="font-weight-bold mb-4 title1" style="line-height: 1">
            Entenda mais sobre a Lei Geral de Proteção de Dados de Forma
            Simplificada e como Implantá-la!
          </div>
          <div class="font-weight-light subtitle1">
            Através deste <b class="font-weight-bold">E-book GRATUITO</b> você
            estará por dentro da <b class="font-weight-bold">LGPD</b> e ainda
            saberá o <b class="font-weight-bold"> Passo a Passo </b> para
            Implementá-la no seu Empreendimento.
          </div>
          <v-btn
            rounded
            large
            dark
            class="mt-5 my-10 orange"
            @click="active = true"
          >
            Garanta seu E-book GRÁTIS!
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-row>
      <v-col cols="10" offset="1">
        <div
          class="font-weight-bold title1 text-center black--text mt-8"
          style="line-height: 1"
        >
          O que você irá encontrar nesse E-book:
        </div>
      </v-col>
    </v-row>

    <v-container fluid class="mt-10 pb-16">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-img
                :src="feature.img"
                max-width="150px"
                class="d-block ml-auto mr-auto"
              />
              <div class="font-weight-regular subtitle1 mt-4">
                {{ feature.title }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet dark color="accent" class="mt-4">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <div
            align="center"
            class="font-weight-bold title1 mt-8"
            style="line-height: 1"
          >
            Solicite <b class="font-weight-bold">AGORA MESMO</b> este
            <b class="font-weight-bold">E-book Gratuito</b><br />
            e o <b class="font-weight-bold">receba diretamente no seu E-mail</b
            ><br />
            clicando no botão abaixo:
          </div>
        </v-col>
      </v-row>
      <div align="center" class="pt-2 pb-5">
        <v-btn
          rounded
          x-large
          dark
          class="mt-5 my-10 orange"
          @click="active = true"
        >
          <template v-if="$vuetify.breakpoint.mobile">
            Clique AQUI e receba o E-book!
          </template>
          <template v-else>
            Clique AQUI e receba o E-book no seu e-mail!
          </template>
        </v-btn>
      </div>
    </v-sheet>

    <v-dialog
      v-model="active"
      max-width="500px"
      :persistent="loading"
    >
      <v-card>
        <div align="end">
          <v-btn
            icon
            @click="active = false"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title class="py-0 justify-center">
          <div align="center" class="subtitle1 ma-5 font-weight-bold break">
            Responda ao simples formulário abaixo para receber o E-Book
            diretamente no seu e-mail. Preencha seus dados abaixo para
            recebê-lo.
          </div>
        </v-card-title>

        <v-card-text>
          <v-form ref="leadForm">
            <v-col cols="12">
              <div
                class="text-center font-weight-bold"
                style="font-size: 18pt"
              ></div>
              <v-text-field
                label="Nome *"
                placeholder="Nome *"
                v-model="lead.name"
                required
                hide-details="auto"
                solo
                class="mt-4"
                :rules="[$rules.required]"
                :disabled="loading"
              />
              <v-text-field
                label="E-Mail *"
                placeholder="E-Mail *"
                v-model="lead.email"
                required
                hide-details="auto"
                solo
                class="mt-4"
                :rules="[$rules.required, $rules.email]"
                :disabled="loading"
              />
              <!-- <v-text-field
                  label="Telefone *"
                  v-model="lead.phone"
                  required
                  hide-details="auto"
                  solo
                  class="mt-4"
                  :rules="[$rules.required, $rules.phone]"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  placeholder="(00) 00000-0000"
                  :disabled="loading"
                /> -->
              <!-- <v-text-field
                  label="Qual a sua Empresa?"
                  placeholder="Qual a sua Empresa?"
                  v-model="lead.company"
                  required
                  hide-details="auto"
                  solo
                  class="mt-4"
                  :disabled="loading"
                />
                <v-text-field
                  label="Qual a seu cargo atual?"
                  placeholder="Qual a seu cargo atual?"
                  v-model="lead.company_position"
                  required
                  hide-details="auto"
                  solo
                  class="mt-4"
                  :disabled="loading"
                /> -->
              <v-checkbox
                label="Concordo em receber comunicações sobre a LGPD e o sistema eConsenso"
                :disabled="loading"
                :rules="[(v) => !!v || 'Você deve concordar para continuar']"
              />
              <div class="text-center" style="line-height: 5">
                <v-btn
                  color="primary"
                  block
                  dark
                  x-large
                  @click="sendForm()"
                  :loading="loading"
                >
                  EU QUERO MEU E-BOOK
                </v-btn>
              </div>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Footer />
  </div>
</template>


<script>
import Footer from "@/layout/default/Footer.vue";

export default {
  name: "LandingEbook",
  components: {
    Footer,
  },
  data: () => ({
    loading: false,
    active: false,
    lead: {
      name: "",
      email: "",
      phone: "",
      company: "",
      company_position: "",
      checkbox: false,
    },
    features: [
      {
        img: require("@/assets/landing-page/ebook/gratuito.png"),
        title:
          "Conteúdo GRATUITO desenvolvido sob supervisão de Advogados e Estudiosos.",
      },
      {
        img: require("@/assets/landing-page/ebook/lgpd-simplificada.png"),
        title:
          "Entenderá de forma Simplificada o que é, como funciona e quais são os desafios da LGPD.",
      },
      {
        img: require("@/assets/landing-page/ebook/passo-a-passo.png"),
        title:
          "Você terá em mãos os 11 Passos para Implantar a LGPD no seu Empreendimento.",
      },
    ],
  }),
  methods: {
    sendForm() {
      if (this.$refs.leadForm.validate()) {
        this.loading = true;
        const queryParams = Object.entries(this.$route.query).map(
          ([key, val]) => `${key}=${val}`
        );
        this.$axios
          .post("/lead", {
            ...this.lead,
            stage: "Educação - LGPD",
            tags: ["LP E-Book", ...queryParams],
          })
          .then(() => {
            this.$showSuccessMessage(
              "Fique atento ao seu E-Mail",
              "Desde já agradecemos pelo seu interesse em nosso E-Book. Em muito breve você o receberá em seu e-mail. Até mais."
            );
            this.$refs.leadForm.reset();
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado. Por favor, contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>


<style scoped>
.break {
  word-break: break-word;
}
.title1 {
  font-size: 45px;
  font-weight: bold;
}
.subtitle1 {
  font-size: 25px;
}
</style>
